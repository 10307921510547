import Account from '@/models/Account';
import axios from 'axios';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';
import { useProfileStore } from '@/store/profile.module';

let axiosInstance: any;
let storeProfile: any;

class AccountService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async find(accountId: string): Promise<Account | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_ACCOUNT_SERVICE}/account/${accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async update(accountId: string, data: Account): Promise<Account | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_ACCOUNT_SERVICE}/account/${accountId}`,
        data,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
}

export const accountService = new AccountService();
